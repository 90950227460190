import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import Rollbar from 'rollbar';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subscription, firstValueFrom } from 'rxjs';
import Daily, {
  DailyCall,
  DailyEventObjectParticipants,
  DailyEventObjectParticipant,
  DailyEventObjectParticipantLeft,
  DailyEventObjectTrack,
  DailyStreamingOptions,
  DailyParticipantUpdateOptions,
  DailyEventObjectFatalError,
  DailyEventObjectRecordingStarted,
  DailyEventObjectRecordingStopped,
  DailyEventObjectRecordingError,
  DailyEventObjectNoPayload,
  DailyParticipant,
} from '@daily-co/daily-js';

import {
  DailyTokenResponse,
  DailyCloudRecordingResponse,
  LiveDailyParticipant,
  ParticipantFsDaily,
  ParticipantFS,
  Roles,
  WebhookEvent,
  WebhookEventNames,
  Locations,
  CloudFunctionFullUrl_PROD,
  CloudFunctionFullUrl_DEV,
} from '@sc/types';
import { environment } from '../../../environments/environment';
import { RollbarService } from '../../services/rollbar/rollbar.service';
import { GeneralToastComponent } from '../../toasts/general-toast/general-toast.component';
import { MuteService } from '../mute/mute.service';
import { VideoOffService } from '../video-off/video-off.service';
import { SessionsService } from '../sessions/sessions.service';
import { UserService } from '../user/user.service';
import { IdTokenService } from '../id-token/id-token.service';
import { RolesService } from '../roles/roles.service';
import { SCSubject } from '../../util/sc-subject.class';
import { EquipmentService } from '../equipment/equipment.service';
import { SettingsService } from '../settings/settings.service';
import { IPadService } from '../ipad/ipad.service';
import { MediaStreamType, MediaStreamWithType } from '@voxeet/voxeet-web-sdk/types/models/MediaStream';
import { AnalyticsService } from '../analytics/analytics.service';
import { LeaveFeedbackToastComponent } from '../../toasts/leave-feedback-toast/leave-feedback-toast.component';
import { Router } from '@angular/router';
import { CalifoneService } from '../califone/califone.service';
import { CloudFunctionsService } from '../cloud-functions.service';
import { arrayUnion, collection, doc, Firestore, setDoc } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class DailyService implements OnDestroy {
  studioSession$ = this.sessionsService.studioSession$;
  activeUser$ = this.userService.activeUser$;
  role$ = this.rolesService.role$;
  dailyCall$: SCSubject<DailyCall> = new SCSubject();
  mute$ = this.muteService.mute$;
  videoOff$ = this.videoOffService.videoOff$;
  screenshare$: SCSubject<ParticipantFsDaily> = new SCSubject();

  localParticipant$ = new SCSubject<ParticipantFsDaily>();
  stageParticipants$ = new SCSubject<Map<string, ParticipantFsDaily>>(new Map<string, ParticipantFsDaily>());
  sessionParticipants$ = new BehaviorSubject<Map<string, ParticipantFS>>(new Map<string, ParticipantFS>());
  nonPrioritySpeakersArray$ = new SCSubject<Array<ParticipantFsDaily>>([]);
  nonPrioritySpeakersArrayObjectFit$ = new SCSubject<Array<ParticipantFsDaily['objectFit']>>([]);
  prioritySpeakersArray$ = new SCSubject<Array<ParticipantFsDaily>>([]);
  prioritySpeakersArrayObjectFit$ = new SCSubject<Array<ParticipantFsDaily['objectFit']>>([]);
  pinnedParticipantsArray$ = new SCSubject<Array<DailyParticipant>>([]);
  recording$ = new SCSubject<boolean>(false);
  location$ = new SCSubject<Locations>();
  leaving$: SCSubject<boolean> = new SCSubject();

  distatone = environment.microservices.distatone;
  dailyURL = environment.daily.url;
  roomName: string;
  meetingToken: string;
  deviceInfo: DeviceInfo;
  microphoneStream: MediaStream;
  cameraStream: MediaStream;
  filterDeviceName = this.equipmentService.filterDeviceName;
  localState = new Map<string, { minimized?: boolean; prioritySpeaker?: boolean; objectFit?: 'contain' | 'cover' }>();
  debug = false;
  subs: Subscription[] = [];
  cloudRecordingTakeID: string;
  takesCol = collection(this.firestore, 'takes');

  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    private http: HttpClient,
    private router: Router,
    private firestore: Firestore,
    private deviceDetectorService: DeviceDetectorService,
    private toastrService: ToastrService,
    private muteService: MuteService,
    private videoOffService: VideoOffService,
    private sessionsService: SessionsService,
    private userService: UserService,
    private idTokenService: IdTokenService,
    private rolesService: RolesService,
    private equipmentService: EquipmentService,
    private settingsService: SettingsService,
    private iPadService: IPadService,
    private analyticsService: AnalyticsService,
    private califoneService: CalifoneService,
    private cfs: CloudFunctionsService
  ) {
    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    this.setupMicrophone();
    this.setupMute();
    this.setupEchoCancellation();
    this.setupCamera();
    this.setupVideoOff();
    this.setupHeadphones();
    this.setupStageParticipants();
    this.setupRecording();
    // this.setupSessionSettings();
  }

  async createRoom(idToken: string, room_name: string) {
    const headers = new HttpHeaders().set('idToken', idToken);
    return this.cfs.post('daily-room', { room_name });
  }

  async deleteRoom(idToken: string, room_name: string) {
    const headers = new HttpHeaders().set('idToken', idToken);
    return this.cfs.delete(`daily-room`, { room_name });
  }

  async getCallToken(idToken: string, room_name: string) {
    const headers = new HttpHeaders().set('idToken', idToken);
    const userId = this.activeUser$.value.uid;
    const token: DailyTokenResponse = (await this.cfs.post('daily-tokens', {
      room_name,
      userId,
      is_owner: this.role$.value >= Roles.SHOW_MANAGER,
    })) as DailyTokenResponse;
    return token;
  }

  async initRoom() {
    // set a timeout for initializing the room
    const initTimeout = setTimeout(() => {
      this.toastrService.error(
        `This may be due to a network issue.  Check your connection, refresh the page, and try again.`,
        `Timed out initializing the session`,
        {
          enableHtml: true,
          progressBar: false,
          closeButton: true,
          tapToDismiss: false,
          toastComponent: GeneralToastComponent,
          disableTimeOut: true,
        }
      );
      return false;
    }, 20_000);

    try {
      // make sure we have a session
      await this.studioSession$.nextExistingValue((session) => session.sessionID);
      if (!this.studioSession$.value) {
        this.rollbar.error('Tried to initialize Daily call without studio session');
        this.toastrService.error(
          `This may be due to a network issue.  Check your connection, refresh the page, and try again.`,
          `Cannot find the session`,
          {
            enableHtml: true,
            progressBar: false,
            closeButton: true,
            tapToDismiss: false,
            toastComponent: GeneralToastComponent,
            disableTimeOut: true,
          }
        );
        return false;
      }
      // get the idToken
      const idToken = await this.idTokenService.getFreshIdToken();
      // create the room, or get it if it already exists
      this.roomName = `session_${this.studioSession$.value.sessionID}`;
      const room = await this.createRoom(idToken, this.roomName);
      this.meetingToken = (await this.getCallToken(idToken, this.roomName)).token;
      if (this.debug) console.log('[DAILY] room:', room);
      if (this.debug) console.log('[DAILY] token:', this.meetingToken);
      // clear the timeout
      clearTimeout(initTimeout);
      // give it a second to clear the timeout
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return true;
    } catch (error) {
      this.rollbar.error('Failed to Initialize Daily Session', error);
      this.toastrService.error(
        `This may be due to a network issue.  Check your connection, refresh the page, and try again.<br/><br/><code>${error.message}</code>`,
        `Failed to initialize the session`,
        {
          enableHtml: true,
          progressBar: false,
          closeButton: true,
          tapToDismiss: false,
          toastComponent: GeneralToastComponent,
          disableTimeOut: true,
        }
      );
      clearTimeout(initTimeout);
      throw error;
    }
  }

  async joinCall(url: string) {
    try {
      // leave the call if already in one
      if (this.dailyCall$.value) await this.leaveCall();
      // create the call
      const call = Daily.createCallObject();
      // make sure we have media streams
      await this.setupMediaStreams();
      // init the lifecycle listeners
      // this has to be before joining the call for the joined and left event to fire
      this.initLifecycle(call);
      // join the call
      await call.join({
        url,
        userName: this.activeUser$.value.displayName,
        token: this.meetingToken,
        audioSource: this.microphoneStream?.getAudioTracks()[0],
        videoSource: this.cameraStream?.getVideoTracks()[0],
        userData: { scUID: this.activeUser$.value.uid },
      });
      // set the call
      this.dailyCall$.next(call);
      // set the media streams to match the mute and videoOff settings
      this.dailyCall$.value.setLocalAudio(!this.mute$.value);
      this.dailyCall$.value.setLocalVideo(!this.videoOff$.value);
      if (this.role$.value >= Roles.SHOW_MANAGER) {
        this.dailyCall$.value.updateParticipant('local', { updatePermissions: { canAdmin: true } });
      }
      // update the headphones if not on mobile
      // unsure if this is necessary like dolby
      if (this.equipmentService.selectedHeadphones$.value?.deviceId && !this.deviceDetectorService.isMobile()) {
        await this.updateHeadphones({ deviceId: 'default' });
      }
      if (this.equipmentService.selectedHeadphones$.value.deviceId !== 'default') {
        setTimeout(() => {
          this.updateHeadphones(this.equipmentService.selectedHeadphones$.value);
        }, 500);
      }

      this.equipmentService.setAvailableDevices(
        this.equipmentService.systemDevices$.value,
        this.sessionsService.studioSessionID$.value,
        this.activeUser$.value.uid
      );
      this.equipmentService.sessionActive = true;
      this.location$.next(Locations.STAGE);

      if (this.debug) console.log('[DAILY] joined call:', call);
      // return the call
      return call;
    } catch (error) {
      console.log('error:', error);
    }
  }

  async leaveCall(getFeedback = true, setLeaving = true) {
    if (this.leaving$.value) return;
    if (setLeaving) this.leaving$.next(true);
    const session = this.studioSession$.value || this.sessionsService.lastSession;
    const sessionID = session?.sessionID || this.studioSession$.value?.sessionID;
    try {
      // leave the call
      if (this.debug) console.log('[DAILY] participantCounts', this.dailyCall$.value?.participantCounts());

      // if the person leaving the call is the last person the in stageParticipants map and they are the active user, clear the map and set the session to inactive
      // this means that the last person in the call is leaving and we are good to destroy this call and delete the room.
      if (this.stageParticipants$.value.size <= 1 && this.stageParticipants$.value.has(this.activeUser$.value.uid)) {
        this.stageParticipants$.next(new Map());
        this.equipmentService.sessionActive = false;
        this.deleteRoom(await this.idTokenService.getFreshIdToken(), `session_${sessionID}`);
      }

      // leave the call
      await this.dailyCall$.value?.leave();
      // reset to defaults
      this.resetDefaults();

      this.analyticsService.track('left session');

      if (getFeedback) {
        if (!this.userService.activeUser$.value.guest) {
          if (this.settingsService.userAppSettings$.value.askFeedback)
            this.toastrService.success(``, `Please rate the quality of your session`, {
              closeButton: true,
              tapToDismiss: false,
              disableTimeOut: true,
              toastComponent: LeaveFeedbackToastComponent,
            });
          this.router.navigate(['/dashboard']);
        } else {
          this.router.navigate(['/auth'], {
            fragment: 'feedback',
          });
        }
      }

      const event: WebhookEvent = {
        name: WebhookEventNames.PARTICIPANT_LEFT,
        date: session.date,
        sessionTitle: session.sessionTitle,
        sessionID: session.sessionID,
        orgID: session.orgID,
        showID: session.showID,
        showName: session.showTitle,
        location: this.router.url.includes('backstage') ? 'backstage' : 'stage',
      };
      await this.califoneService.emitWebhookEvent(event, session.orgID);

      if (setLeaving) {
        setTimeout(() => {
          this.leaving$.next(false);
        }, 500);
      }

      if (this.debug) console.log('[DAILY] left call');
    } catch (error) {
      console.log('error:', error);
    }
  }

  async startScreenShare() {
    try {
      // get the call
      const call = this.dailyCall$.value;
      // start the screen share
      call.startScreenShare();

      if (this.debug) console.log('[DAILY] startScreenShare');
    } catch (error) {
      console.log('[DAILY] startScreenShare', error);
    }
  }

  async stopScreenShare() {
    try {
      // get the call
      const call = this.dailyCall$.value;
      // stop the screen share
      call.stopScreenShare();

      if (this.debug) console.log('[DAILY] stopScreenShare');
    } catch (error) {
      console.log('[DAILY] stopScreenShare', error);
    }
  }

  initLifecycle(call: DailyCall) {
    call
      // local participant
      .on('joined-meeting', (event) => this.handleJoinedCall(event))
      .on('left-meeting', this.handleLeftCall)
      // all participants
      .on('participant-joined', (event) => this.handleParticipantJoined(event))
      .on('participant-updated', (event) => this.handleParticipantUpdated(event))
      .on('participant-left', (event) => this.handleParticipantLeft(event))
      // track events
      .on('track-started', (event) => this.handleTrackStarted(event, call.callClientId))
      .on('track-stopped', (event) => this.handleTrackStopped(event))
      .on('error', (error) => this.handleError(error))
      // cloud recording events
      .on('recording-started', (event) => this.handleRecordingStarted(event))
      .on('recording-stopped', (event) => this.handleRecordingStopped(event))
      .on('recording-error', (event) => this.handleRecordingError(event))
      .on('recording-upload-completed', (event) => this.handleRecordingUploadCompleted(event));
  }

  destroyLifecycle(call: DailyCall) {
    call
      // local participant
      .off('joined-meeting', this.handleJoinedCall)
      .off('left-meeting', this.handleLeftCall)
      // all participants
      .off('participant-joined', this.handleParticipantJoined)
      .off('participant-updated', this.handleParticipantUpdated)
      .off('participant-left', this.handleParticipantLeft)
      // track events
      .off('track-started', (event) => this.handleTrackStarted(event, call.callClientId))
      .off('track-stopped', this.handleTrackStopped)
      .off('error', this.handleError)
      // cloud recording events
      .off('recording-started', this.handleRecordingStarted)
      .off('recording-stopped', this.handleRecordingStopped)
      .off('recording-error', this.handleRecordingError)
      .off('recording-upload-completed', this.handleRecordingUploadCompleted);
  }

  async handleError(error: DailyEventObjectFatalError) {
    switch (error.action) {
      case 'error': {
        if (error.error?.type === 'ejected') {
          if (!this.userService.activeUser$.value.guest) {
            if (this.settingsService.userAppSettings$.value.askFeedback)
              this.toastrService.success(``, `Please rate the quality of your session`, {
                closeButton: true,
                tapToDismiss: false,
                disableTimeOut: true,
                toastComponent: LeaveFeedbackToastComponent,
              });
            this.router.navigate(['/dashboard']);
          } else {
            this.router.navigate(['/auth'], {
              fragment: 'feedback',
            });
          }
        }
        break;
      }
      default: {
        console.log('error:', error);
        break;
      }
    }
  }

  async handleJoinedCall(event: DailyEventObjectParticipants) {
    if (this.debug) console.log('[DAILY] handleJoinedCall', event.participants);

    const participant = event.participants.local;
    const id = this.activeUser$.value.uid;
    const p = { ...participant, ...this.sessionParticipants$.value.get(id) } as LiveDailyParticipant;

    if (this.localState.has(id)) {
      const { minimized, prioritySpeaker, objectFit } = this.localState.get(id);
      if (minimized) p.minimized = true;
      if (prioritySpeaker) p.prioritySpeaker = true;
      if (objectFit) p.objectFit = objectFit;
    }

    this.localParticipant$.next({ ...participant });
    this.stageParticipants$.next(this.stageParticipants$.value.set(id, p));

    // p.activeStream = new MediaStream([
    //   participant.tracks.video.track,
    //   participant.tracks.audio.track,
    // ]) as MediaStreamWithType;
    const audioTrack = participant.tracks.audio.persistentTrack;
    const videoTrack = participant.tracks.video.persistentTrack;

    if (audioTrack instanceof MediaStreamTrack && !videoTrack) {
      p.activeStream = new MediaStream([audioTrack]) as MediaStreamWithType;
    } else if (audioTrack instanceof MediaStreamTrack && videoTrack instanceof MediaStreamTrack) {
      p.activeStream = new MediaStream([audioTrack, videoTrack]) as MediaStreamWithType;
    } else {
      console.error('Invalid MediaStreamTrack:', { audioTrack, videoTrack });
      // Handle the error appropriately, e.g., set p.activeStream to null or a default value
      p.activeStream = new MediaStream([
        participant.tracks.video.track,
        participant.tracks.audio.track,
      ]) as MediaStreamWithType;
    }
    p.activeStream.type = 'video' as MediaStreamType;

    if (this.debug) console.log('[DAILY] p', p);
    if (this.debug) console.log('[DAILY] localParticipant:', this.localParticipant$.value);
    if (this.debug) console.log('[DAILY] stageParticipants:', this.stageParticipants$.value);
  }

  async handleLeftCall() {
    if (this.debug) console.log('[DAILY] handleLeftCallMeeting');
  }

  async handleParticipantJoined(event: DailyEventObjectParticipant) {
    const participant = event.participant as LiveDailyParticipant;
    const id = participant.userData.scUID;

    if (!id) {
      console.warn('handleParticipantAdded: Participant has no externalId', participant);
      return;
    }

    if (this.stageParticipants$.value.has(id)) {
      console.warn('handleParticipantAdded: Participant already in stage', participant);
      return;
    }

    const p = {
      ...participant,
      ...this.sessionParticipants$.value.get(id),
    } as LiveDailyParticipant;
    if (this.localState.has(id)) {
      const { minimized, prioritySpeaker, objectFit } = this.localState.get(id);
      if (minimized) p.minimized = true;
      if (prioritySpeaker) p.prioritySpeaker = true;
      if (objectFit) p.objectFit = objectFit;
    }

    this.playJoinSessionAudio();
    this.stageParticipants$.next(this.stageParticipants$.value.set(id, p));

    if (this.debug) console.log('[DAILY] handleParticipantJoined', event.participant);
  }

  async handleParticipantUpdated(event: DailyEventObjectParticipant) {
    if (this.debug) console.log('[DAILY] handleParticipantUpdated', event.participant);

    const { participant } = event;

    if (participant.local) {
      this.localParticipant$.next({ ...participant });
      return;
    }

    // TODO: handle participant leaving like Dolby??
    // dolby.service.ts:765
  }

  async handleParticipantLeft(event: DailyEventObjectParticipantLeft) {
    if (this.debug) console.log('[DAILY] handleParticipantLeft', event);
    const participant = event.participant as LiveDailyParticipant;
    const id = participant.userData.scUID;

    if (id === this.localParticipant$.value.uid) {
      this.localParticipant$.next({ ...participant });
      return;
    }

    const participants = this.stageParticipants$.value;
    participants.delete(id);
    this.stageParticipants$.next(participants);
    if (id !== this.activeUser$.value.uid && !id.includes('_screen')) this.playLeaveSessionAudio();
  }

  async handleTrackStarted(event: DailyEventObjectTrack, callClientId: string) {
    const participant: ParticipantFsDaily = event.participant;
    const { audio, video } = participant.tracks ?? {};

    if (event.type === 'screenVideo') {
      const stream = new MediaStream([event.track]) as MediaStreamWithType;
      stream.type = 'screen' as MediaStreamType;
      const id = participant.userData.scUID + '_screen';
      const stageParticipant = {
        ...this.stageParticipants$.value.get(participant.userData.scUID),
        prioritySpeaker: true,
        objectFit: 'contain',
        activeStream: stream,
      } as ParticipantFsDaily;
      this.stageParticipants$.next(this.stageParticipants$.value.set(id, stageParticipant));
      this.screenshare$.next(stageParticipant);
    } else {
      let stageParticipant = this.stageParticipants$.value.get(participant.userData.scUID);

      if (participant.userData.scUID === this.activeUser$.value.uid) {
        this.equipmentService.setEquipmentMetrics(audio?.persistentTrack, video?.persistentTrack);
      }

      if (!stageParticipant) {
        this.handleParticipantJoined({
          action: 'participant-joined',
          participant: event.participant,
          callClientId,
        });
        stageParticipant = this.stageParticipants$.value.get(participant.userData.scUID);
      }

      if (!stageParticipant.activeStream) {
        const tracks = [
          ...(audio?.persistentTrack ? [audio.persistentTrack] : []),
          ...(video?.persistentTrack ? [video.persistentTrack] : []),
        ];
        stageParticipant.activeStream = new MediaStream(tracks) as MediaStreamWithType;
      } else {
        if (audio?.track) {
          const existingAudioTrack = stageParticipant.activeStream.getTracks().find((t) => t.kind === 'audio');
          if (existingAudioTrack) stageParticipant.activeStream.removeTrack(existingAudioTrack);
          stageParticipant.activeStream.addTrack(audio.persistentTrack);
        }
        if (video?.track) {
          const existingVideoTrack = stageParticipant.activeStream.getTracks().find((t) => t.kind === 'video');
          if (existingVideoTrack) stageParticipant.activeStream.removeTrack(existingVideoTrack);
          stageParticipant.activeStream.addTrack(video.persistentTrack);
        }
      }

      if (event.track.readyState === 'ended') {
        const { sessionID } = this.studioSession$.value;
        const { uid } = this.activeUser$.value;
        this.videoOffService.setVideoOff(sessionID, uid, true);
        await this.sessionsService.participantEquipment$.nextExistingValue((eq) => (eq.videoOff = true));
        await new Promise((resolve) => setTimeout(resolve, 500));
        this.videoOffService.setVideoOff(sessionID, uid, false);
      } else {
        stageParticipant.activeStream.type = (
          stageParticipant.activeStream.getVideoTracks().length ? 'video' : 'audio'
        ) as MediaStreamType;
        this.stageParticipants$.next(
          this.stageParticipants$.value.set(participant.userData.scUID, stageParticipant),
          true
        );
      }
    }

    if (this.debug) console.log('[DAILY] handleTrackStarted', event);
  }

  handleTrackStopped(event: DailyEventObjectTrack) {
    if (event.type === 'screenVideo') {
      const participants = this.stageParticipants$.value;
      const participant = event.participant as LiveDailyParticipant;
      participants.delete(participant.userData.scUID + '_screen');
      this.stageParticipants$.next(participants);
      this.screenshare$.next(null);
    }

    if (this.debug) console.log('[DAILY] handleTrackStopped', event);
  }

  async handleRecordingStarted(event: DailyEventObjectRecordingStarted) {
    if (this.debug) console.log('[DAILY] handleRecordingStarted', event);
    if (this.debug) console.log('[DAILY] Cloud Recording ID', event.recordingId);
    if (this.debug) console.log('[DAILY] Cloud Recording Take ID', this.cloudRecordingTakeID);
    // this.addCloudRecordingToTake(this.cloudRecordingTakeID, event.recordingId);
    // this.playRecordingStartAudio();
  }

  async handleRecordingStopped(event: DailyEventObjectRecordingStopped) {
    if (this.debug) console.log('[DAILY] handleRecordingStopped', event);
    this.playRecordingStopAudio();
  }

  async handleRecordingError(event: DailyEventObjectRecordingError) {
    if (this.debug) console.log('[DAILY] handleRecordingError', event);
  }

  async handleRecordingUploadCompleted(event: DailyEventObjectNoPayload) {
    if (this.debug) console.log('[DAILY] handleRecordingUploadCompleted', event);
  }

  async updateMicrophone(microphone) {
    try {
      await this.equipmentService.constraints$.nextExistingValue((constraints) => {
        return constraints?.audio?.deviceId?.exact === microphone.deviceId;
      });
      this.microphoneStream.getAudioTracks().forEach((track) => track.stop());
      this.microphoneStream = await navigator.mediaDevices.getUserMedia({
        audio: this.equipmentService.constraints$.value.audio,
      });
      // set the audio source
      await this.dailyCall$.value.setInputDevicesAsync({
        audioSource: this.microphoneStream.getAudioTracks()[0],
      });
    } catch (error) {
      console.log('error:', error);
    }
  }

  async updateCamera(camera: MediaDeviceInfo) {
    try {
      await this.equipmentService.constraints$.nextExistingValue((constraints) => {
        console.log('constraints', constraints, camera);
        return constraints?.video?.deviceId?.exact === camera.deviceId;
      });
      this.cameraStream.getVideoTracks().forEach((track) => track.stop());
      this.cameraStream = await navigator.mediaDevices.getUserMedia({
        video: this.equipmentService.constraints$.value.video,
      });
      // set the video source
      await this.dailyCall$.value.setInputDevicesAsync({
        videoSource: this.cameraStream.getVideoTracks()[0],
      });
    } catch (error) {
      console.log('error:', error);
    }
  }

  async updateHeadphones(headphones: Partial<MediaDeviceInfo>) {
    try {
      if (!this.dailyCall$.value || this.deviceInfo.browser === 'Firefox') return;
      await this.dailyCall$.value.setOutputDeviceAsync({ outputDeviceId: headphones.deviceId });
    } catch (error) {
      console.log('error:', error);
    }
  }

  /**
   * Updates the array of participants while preventing duplicates
   */
  updateParticipantArrays(participants: Map<string, LiveDailyParticipant>) {
    const prioritySpeakersArray = [];
    const nonPrioritySpeakersArray = [];
    const priorityObjectFit = [];
    const nonPriorityObjectFit = [];
    const pinnedParticipantsArray = [];

    participants.forEach((participant) => {
      if (!participant.minimized) {
        if (participant.prioritySpeaker) {
          console.log('[DAILY] participant.userData.scUID', participant.userData.scUID);

          prioritySpeakersArray.push(participant);
          priorityObjectFit.push(participant.objectFit);
          if (!participant.userData.scUID.includes('_screen')) pinnedParticipantsArray.push(participant);
        } else {
          nonPrioritySpeakersArray.push(participant);
          nonPriorityObjectFit.push(participant.objectFit);
        }
      }
    });

    this.prioritySpeakersArray$.next(prioritySpeakersArray);
    this.nonPrioritySpeakersArray$.next(nonPrioritySpeakersArray);
    this.prioritySpeakersArrayObjectFit$.next(priorityObjectFit);
    this.nonPrioritySpeakersArrayObjectFit$.next(nonPriorityObjectFit);
    this.pinnedParticipantsArray$.next(pinnedParticipantsArray);
  }

  updateParticipantProperty(id, property, value) {
    const participants = new Map(this.stageParticipants$.value);
    const participant = { ...participants.get(id) };
    participant[property] = value;
    participants.set(id, participant);
    this.stageParticipants$.next(participants);

    const localState = this.localState.get(id) || {};
    localState[property] = value;
    this.localState.set(id, localState);

    // Special handling for localParticipant$
    if (property === 'minimized' && id === this.localParticipant$.value.userData.scUID) {
      this.localParticipant$.next(participant);
    }
  }

  minimizeLocalParticipant(minimize) {
    const localID = this.localParticipant$.value.userData.scUID;
    this.updateParticipantProperty(localID, 'minimized', minimize);
  }

  setPrioritySpeaker(id, priority) {
    this.updateParticipantProperty(id, 'prioritySpeaker', priority);
  }

  setObjectFitCover(id, objectFit) {
    this.updateParticipantProperty(id, 'objectFit', objectFit);
  }

  /**
   * Plays the zap sound when a new participant joins the call
   */
  playJoinSessionAudio() {
    if (this.settingsService.studioShowSettings$.value.muteConfAudioNotifications) return;
    if (this.deviceInfo.browser !== 'Safari' && this.deviceInfo.device !== 'iPhone' && !this.iPadService.check()) {
      const zap = document.querySelector('#zap') as HTMLAudioElement;
      zap.playbackRate = 1;
      zap?.play();
    }
  }

  /**
   * Plays the zap sound when a participant leaves the call
   */
  playLeaveSessionAudio() {
    if (this.settingsService.studioShowSettings$.value.muteConfAudioNotifications) return;
    if (this.deviceInfo.browser !== 'Safari' && this.deviceInfo.device !== 'iPhone' && !this.iPadService.check()) {
      const zap = document.querySelector('#zap') as HTMLAudioElement;
      zap.playbackRate = 2;
      zap?.play();
    }
  }

  /**
   * Plays the start tone when recording is started
   */
  playRecordingStartAudio() {
    const startTone = this.settingsService.studioShowSettings$.value?.recordingTone;
    if (startTone) {
      if (startTone === 3) {
        const alert = document.getElementById('aria-alert');
        alert.setAttribute('aria-label', 'Recording Started');
      } else if (
        this.deviceInfo.browser !== 'Safari' &&
        this.deviceInfo.device !== 'iPhone' &&
        !this.iPadService.check()
      ) {
        const start = new Audio(`assets/audio/record-start${startTone}.mp3`);
        if (start) {
          start.play().catch((error) => console.error('Error playing start audio:', error));
        }
      }
    }
  }

  /**
   * Plays the stop tone when recording is started
   */
  playRecordingStopAudio() {
    const stopTone = this.settingsService.studioShowSettings$.value?.recordingTone;
    if (stopTone) {
      if (stopTone === 3) {
        const alert = document.getElementById('aria-alert');
        alert.setAttribute('aria-label', 'Recording Stopped');
      } else if (
        this.deviceInfo.browser !== 'Safari' &&
        this.deviceInfo.device !== 'iPhone' &&
        !this.iPadService.check()
      ) {
        const stop = new Audio(`assets/audio/record-stop${stopTone}.mp3`);
        stop.play();
      }
    }
  }

  setupMute() {
    this.mute$.subscribe(async (mute: boolean) => {
      const enabled = !mute;
      this.dailyCall$.value?.setLocalAudio(enabled);
    });
  }

  setupVideoOff() {
    this.videoOff$.subscribe(async (videoOff: boolean) => {
      const enabled = !videoOff;
      this.dailyCall$.value?.setLocalVideo(enabled);
    });
  }

  setupSessionSettings() {
    this.studioSession$.subscribe(async (session) => {
      if (!session) return;
      if (this.debug) console.log('[DAILY] session:', session);
    });
  }

  async setupMediaStreams() {
    try {
      this.microphoneStream = await navigator.mediaDevices.getUserMedia({
        audio: this.equipmentService.constraints$.value.audio,
      });
      if (this.equipmentService.constraints$.value.video) {
        this.cameraStream = await navigator.mediaDevices.getUserMedia({
          video: this.equipmentService.constraints$.value.video,
        });
      }
    } catch (error) {
      console.log('error:', error);
    }
  }

  async setEchoCancellation() {
    this.microphoneStream.getAudioTracks().forEach((track) => {
      track.stop();
    });
    this.microphoneStream = await navigator.mediaDevices.getUserMedia({
      audio: this.equipmentService.constraints$.value.audio,
    });
    await this.dailyCall$.value?.setInputDevicesAsync({
      audioSource: this.microphoneStream.getAudioTracks()[0],
    });

    /** Removing Noise Cancellation from Daily and using just the Echo Cancellation from MediaStream
     * This causes unwanted issues with the recordings and the audio quality causing them to sound lower quality.
     * Until more testing is done this feature is disabled. - Jean
     *
     * https://docs.daily.co/reference/daily-js/instance-methods/update-input-settings#audio-processor
     */
    // this.dailyCall$.value?.updateInputSettings({
    //   audio: {
    //     processor: {
    //       type: echoCancellation ? 'noise-cancellation' : 'none',
    //     },
    //   },
    // });
  }

  setupEchoCancellation() {
    const sub = this.equipmentService.echoCancellation$.subscribe(async (echoCancellation) => {
      if (!this.dailyCall$.value) return;
      try {
        await this.equipmentService.constraints$.nextExistingValue((constraints) => {
          return constraints?.audio?.echoCancellation === echoCancellation;
        });
        await this.setEchoCancellation();
        const state = echoCancellation ? 'Enabled' : 'Disabled';
        this.toastrService.success(`Successfully ${state} Echo Cancellation!`, `Echo Cancellation ${state}`, {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 5 * 1000,
          toastComponent: GeneralToastComponent,
        });
      } catch (error) {
        console.log('error:', error);
      }
    });

    this.subs.push(sub);
  }

  setupMicrophone() {
    const sub = this.equipmentService.selectedMicrophone$.subscribe(async (microphone) => {
      if (!microphone) return;
      if (!this.dailyCall$.value) return;

      try {
        await this.updateMicrophone(microphone);
        this.toastrService.success(this.filterDeviceName(microphone.label), `Successfully connected to Microphone`, {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 5 * 1000,
          toastComponent: GeneralToastComponent,
        });
      } catch (error) {
        let message = `Failed to connect to Microphone`;
        if (this.studioSession$.value.recording) {
          message = message + '.  Your recording will need to be restarted.';
        }
        this.toastrService.error(this.filterDeviceName(microphone.label), message, {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 5 * 1000,
          toastComponent: GeneralToastComponent,
        });
        throw error;
      }
    });

    this.subs.push(sub);
  }

  setupCamera() {
    const sub = this.equipmentService.selectedCamera$.subscribe(async (camera) => {
      if (!camera) return;
      if (!this.dailyCall$.value) return;
      try {
        await this.updateCamera(camera);
        this.toastrService.success(this.filterDeviceName(camera.label), `Successfully connected to Camera`, {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 5 * 1000,
          toastComponent: GeneralToastComponent,
        });
      } catch (error) {
        let message = `Failed to connect to Camera`;
        if (this.studioSession$.value.recording) {
          message = message + '.  Your recording will need to be restarted.';
        }
        this.toastrService.error(this.filterDeviceName(camera.label), message, {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 5 * 1000,
          toastComponent: GeneralToastComponent,
        });
        throw error;
      }
    });

    this.subs.push(sub);
  }

  setupHeadphones() {
    const sub = this.equipmentService.selectedHeadphones$.subscribe(async (headphones) => {
      if (!headphones) return;
      if (!this.dailyCall$.value) return;

      try {
        await this.updateHeadphones(headphones);
        this.toastrService.success(this.filterDeviceName(headphones.label), `Successfully connected to Headphones`, {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 5 * 1000,
          toastComponent: GeneralToastComponent,
        });
      } catch (error) {
        this.toastrService.error(this.filterDeviceName(headphones.label), `Failed to connect to Headphones`, {
          progressBar: true,
          progressAnimation: 'decreasing',
          closeButton: true,
          tapToDismiss: false,
          timeOut: 5 * 1000,
          toastComponent: GeneralToastComponent,
        });
        throw error;
      }
    });

    this.subs.push(sub);
  }

  kickFromConversation(participant: ParticipantFsDaily) {
    const dailyCallOptions: DailyParticipantUpdateOptions = {
      eject: true,
    };

    return this.dailyCall$.value.updateParticipant(participant.session_id, dailyCallOptions);
  }

  setupStageParticipants() {
    this.stageParticipants$.subscribe((participants) => {
      if (this.debug) console.log('[DAILY] participants:', participants);
      this.updateParticipantArrays(participants);
    });
  }

  setupRecording() {
    this.recording$.subscribe(async (recording) => {
      if (recording) this.playRecordingStartAudio();
      else this.playRecordingStopAudio();
    });
  }

  startRecording(takeId?: string) {
    const options: DailyStreamingOptions<'recording'> = {};
    this.cloudRecordingTakeID = takeId;
    this.dailyCall$.value.startRecording(options);
  }

  stopRecording() {
    this.dailyCall$.value.stopRecording();
  }

  async getDailyRecordingUrl(recordingID: string, sessionID: string) {
    const idToken = await this.idTokenService.getFreshIdToken();
    const headers = new HttpHeaders().set('idToken', idToken);

    let dailyResponse: DailyCloudRecordingResponse;

    if (environment.production) {
      dailyResponse = (await this.cfs.getSpecific(`${CloudFunctionFullUrl_PROD.DAILY_RECORDING}/${recordingID}`, {
        sessionID,
      })) as DailyCloudRecordingResponse;
    } else {
      dailyResponse = (await this.cfs.getSpecific(`${CloudFunctionFullUrl_DEV.DAILY_RECORDING}/${recordingID}`, {
        sessionID,
      })) as DailyCloudRecordingResponse;
    }

    return dailyResponse;

    // return await firstValueFrom(
    //   this.http.get<DailyCloudRecordingResponse>(`${this.distatone}/api/v6/cloudRecordings/${recordingID}`, {
    //     headers,
    //     params: new HttpParams().set('sessionID', sessionID),
    //   })
    // );
  }

  addCloudRecordingToTake(takeID: string, recordingID: string) {
    return setDoc(doc(this.takesCol, takeID), { cloudRecordings: arrayUnion(recordingID) }, { merge: true });
  }

  async resetDefaults() {
    // destroy the lifecycle listeners
    if (this.dailyCall$.value) this.destroyLifecycle(this.dailyCall$.value);
    // destroy the call
    await this.dailyCall$.value?.destroy();

    this.equipmentService.sessionActive = false;
    this.dailyCall$.next(null);
    this.localParticipant$.next(null);
    this.screenshare$.next(null);
    this.stageParticipants$.next(new Map());
    this.nonPrioritySpeakersArray$.next([]);
    this.nonPrioritySpeakersArrayObjectFit$.next([]);
    this.prioritySpeakersArray$.next([]);
    this.prioritySpeakersArrayObjectFit$.next([]);
    if (this.microphoneStream?.active) {
      this.microphoneStream.getAudioTracks().forEach((track) => track.stop());
    }
    if (this.cameraStream?.active) {
      this.cameraStream.getVideoTracks().forEach((track) => track.stop());
    }
  }

  async ngOnDestroy() {
    await this.resetDefaults();
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
